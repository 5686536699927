<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
      :mark="mark"
    />
    <!-- <ImageList :images="images"></ImageList> -->

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`产品优势`"
      :en_title="`Product Advantages`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist1"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`应用场景`"
      :en_title="`Application Scenarios`"
    ></Paragraph>

    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      <Lettertitleblock
        :datalist="datalist2"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      ></Lettertitleblock>
    </div>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`应用案例`"
      :en_title="`Applications`"
    ></Paragraph>
    <Logotitleboxwtc :datalist="datalist3"></Logotitleboxwtc>
    <Logotitleboxwtc :datalist="datalist4"></Logotitleboxwtc>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Logotitleboxwtc from '@/components/chain/basic/common/logotitleboxwtc';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Titlestrlist,
    Logotitleboxwtc,
    Lettertitleblock,
  },
  data() {
    return {
      title: '梧桐链服务',
      body:
        '梧桐链是由同济大学牵头联合各领域领先企业共同发起研发、打造的国际领先、'
        + '具有自主知识产权的联盟链底层技术平台，希望通过整合项目经验、产业和社区资源，研发与行业应用场景高度融合的区块链底层平台。',
      image: require('@/assets/image/chain/alliance/wtc/wtc.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/alliance/wtc/22_01.jpg'),
        require('@/assets/image/chain/alliance/wtc/22_02.jpg'),
        require('@/assets/image/chain/alliance/wtc/22_03.jpg'),
      ],
      mark: '1',
      datalist1: [
        {
          title: '> ',
          content:
            '按照应用场景和网络环境，智能适配不同的共识算法,多种共识算法适配，包括Raft, PBFT等',
        },
        {
          title: '> ',
          content: '针对应用特点定制架构,针对网络结构做性能优化',
        },
        {
          title: '> ',
          content: '支持基于Docker和虚拟机的智能合约,独立研发脚本解析虚拟机',
        },
        {
          title: '> ',
          content:
            '保证数据的安全性和完整性、保护交易的安全隐私,支持标准的ECC加密方式和国密加密方式',
        },
        {
          title: '> ',
          content:
            '结合区块链技术的定位、重点工作、技术创新、产业发展等部署开发运营',
        },
      ],
      datalist2: [
        {
          title: '物流航运',
          imgsrc: 'A',
          list: ['优化业务流程', '提高业务效率'],
        },
        {
          title: '互联网金融',
          imgsrc: 'B',
          list: ['服务延伸终端', '创造合作模式'],
        },
        {
          title: '电子政务',
          imgsrc: 'C',
          list: ['数据保全、安全性高', '提高政务公开性、透明性'],
        },
        {
          title: '证书平台',
          imgsrc: 'D',
          list: ['保存长久性', '保证真实性'],
        },
        {
          title: '交易平台',
          imgsrc: 'E',
          list: ['信息化、规范化、透明化', '提高效率、节约成本'],
        },
      ],
      datalist3: [
        {
          imageurl: require('@/assets/image/chain/alliance/wtc/layer1.png'),
          title: '公证摇号',
          list: [
            '以区块链的透明、可追溯特性',
            '结合密码学手段和区块链产生安全随机数',
            '通过技术+公证公信力保障公众权益',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/chain/alliance/wtc/layer2.png'),
          title: '中远海运',
          list: [
            '通过区块链技术将各方的关键业务数据存证和共享',
            '提高数据可信度',
            '降低业务方之前的信任成本',
            '进而优化业务流程',
            '提高业务效率',
          ],
        },
        {
          imageurl: require('@/assets/image/chain/alliance/wtc/layer3.png'),
          title: '泰丰艺术品',
          list: [
            '以区块链的不可篡改、透明共享、可追',
            '溯特性实现字画登记、交易、防伪',
            '同时画家信息上链存证',
            '为字画防伪溯源提供技术公信力',
            '&nbsp;',
          ],
        },
      ],
      datalist4: [
        {
          imageurl: require('@/assets/image/chain/alliance/wtc/layer4.png'),
          title: '行政执法',
          list: [
            '利用区块链技术固化保全数据',
            '实现行政执法全过程跟踪记录、实时留痕和可回溯管理',
            '提高行政执法的公开性、透明性',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/chain/alliance/wtc/layer5.png'),
          title: '海关监督',
          list: [
            '通过全过程数据共享',
            '实现以假设信任为前提的事中+事后监督',
            '降低卡口查验率',
            '提高通关服务效率',
            '节约成本',
          ],
        },
        {
          imageurl: require('@/assets/image/chain/alliance/wtc/layer6.png'),
          title: '宝武集团',
          list: [
            '基于区块链的数字信用凭证',
            '实现核心企业优质信用资源向中小企业分享',
            '提高供应链资金流转效率',
            '基于梧桐链联盟',
            '共同维护业务过程的真实有效',
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
